// Main Article
.main-article {
    @media screen and (min-width: 769px) {
        .article-page {
            display: grid;
            grid-gap: 20px;
            grid-template-columns: calc(100% - 320px) 300px;
        }
        .article-page {
            article:not(.read-next) {
                display: block;
                width: 100%;
                h2, h3, h4 {
                    @include rem('margin-bottom', 20);
                }
            }
        }
    }
    .article-page {
        > article {
            h1 {
                text-transform: none;
                @include rem('font-size', 24);
                margin-bottom: 30px;
            }
            a {
                color: $red;
                &:hover {
                    color: $red;
                }
            }
        }
    }
    article {
        // padding: 20px 20px 0;
        @media screen and (min-width: 768px) {
            padding-top: 0;
            h1 {
                margin-top: 0;
            }
        }
    }
    // > .wrapper {
    //     video, .iframe, iframe:not([id^='google_ads_iframe_']), .twitter-tweet, .fb-video {
    //         margin: 20px auto;
    //         width: 100%;
    //         text-align: center;
    //         iframe {
    //             margin: 0 auto;
    //         }
    //     }
    // }
    > article:first-of-type {
        @media screen and (min-width: 970px) {
            &:not(.related) {
                width: calc(100% - 300px);
                margin: auto;
            }
        }
        .sharethis-inline-share-buttons {
            padding: 10px 0;
        }
        @media screen and (max-width: 1000px) {
            header {
                padding: 0 20px 10px 20px;
            }
            .sharethis-inline-share-buttons {
                margin: 0 -10px;
            }
        }
    }
    .section {
        margin: 0;
        @include rem('font-size', 14);
    }
    .meta {
        margin: 0;
        p {
            margin: 0;
            padding: 5px 0;
        }
    }
    img {
        margin: auto;
        max-width: 100%;
        height: auto;
    }
    figure {
        margin: 0;
        img {
            width: 100%;
        }
        figcaption {
            padding: 10px 0;
            width: 100%;
            @include rem('font-size', 12);
            line-height: 1.4;
            font-style: italic;
            color: $grey;
        }
    }
    .article-carousel {
        img {
            margin: auto;
        }
    }
    // article, aside:not(.sidebar) {
    //     video, .iframe, iframe:not([id^='google_ads_iframe_']) {
    //         margin: 20px auto;
    //         max-width: 100%;
    //     }
    // }
    b + figcaption {
        padding: 10px 0;
    }
    .articleBodyMore {
        p {
            &:first-of-type {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 1em;
            }
        }
        h4, h5 {
            margin: 20px 0;
        }
        figure {
            margin: 20px 0;
        }
        a {
            color: $black;
            font-weight: 600;
            &:hover {
                color: $black;
            }
        }
        p[class*='link-'] {
            svg {
                display: none;
            }
            @include rem('font-size', 14);
            text-transform: uppercase;
        }
    }
    .iframeWrapper {
        position: relative;
        overflow: hidden;
        clear: both;
        margin: 20px 0;
        iframe {
            margin: 0 auto;
        }
    }
    .article-body {
        .meta {
            @include rem('font-size', 14);
            margin-bottom: 20px;
        }
    }
}

.you-might-also-like {
    margin-top: 20px;
    display: grid;
    grid-gap: 20px 15px;
    grid-template-columns: 1fr 1fr;
    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    h2 {
        font-weight: 400;
        width: 100%;
        display: block;
        grid-column: 1 / span 2;
        @media screen and (min-width: 768px) {
            grid-column: 1 / span 4;
        }
    }
    article {
        text-align: center;
        img {
            width: 100%;
        }
    }
    h3 {
        @include rem('font-size', 14);
        margin-top: 10px;
        color: $default;
        border-bottom: 0 none;
        text-transform: none;
        line-height: 1.2;
    }
    a {
        color: $default;
        @include trans();
        @include font();
        &:hover {
            color: $red;
            h3 {
                color: $red;
            }
        }
    }
    .thumbnails-a .syndicatedItem .video-title {
        @include font();
        @include rem('font-size', 14);
        &:hover {
            color: $red;
            text-decoration: none;
        }
    }
    .trc_related_container {
        grid-column: 1 / span 4;
    }
}

// Social Share
.sharethis-inline-share-buttons {
    @media screen and (min-width: 768px) {
        grid-column: 2 / 2;
    }
    margin-bottom: 20px;
    p {
        display: inline-block;
        margin: 0;
        margin-right: 5px;
        vertical-align: middle;
    }
    button {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        color: $white;
        line-height: 1;
        @include rem('font-size', 12);
        text-transform: uppercase;
        position: relative;
        margin-right: 5px;
        @include trans();
        cursor: pointer;
        > div {
            display: inline-block;
            vertical-align: middle;
        }
        > span {
            position: relative;
            top: 2px;
            display: none;
        }
        @media screen and (max-width: 374px) {
            span {
                display: none;
            }
        }
        &.SocialMediaShareButton--facebook {
            background: #3b5998;
        }
        &.SocialMediaShareButton--twitter {
            background: #00aced;
        }
        &.SocialMediaShareButton--linkedin {
            background: #007fb1;
        }
        &.SocialMediaShareButton--pinterest {
            background: #cb2128;
        }
        &.SocialMediaShareButton--whatsapp {
            background: #2cb742;
        }
        &.SocialMediaShareButton--email {
            background: #7f7f7f;
        }
        &:hover {
            @include trans();
            top: -5px;
        }
    }
}

// Tags
.article-tags {
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
        margin-bottom: 0;
        grid-column: 2 / 2;
    }
    background: url('../../static/icons/tags.svg') no-repeat left center;
    padding-left: 25px;
}

.articleBodyMore {
    form {
        margin: 20px 0;
    }
    input, textarea, select {
        width: 100%;
        padding: 10px;
        border: 1px solid #DDD;
        resize: none;
        margin: 5px 0;
        @include trans();
        &:hover, &:focus {
            border-color: $red;
        }
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: $white;
        background: url('../../static/icons/angle-down.svg') no-repeat right 10px center;
    }
    button {
        margin-top: 10px;
    }
    fieldset {
        border: 0 none;
        padding: 0;
    }
    div[class*='checkboxes'] {
        input {
            width: auto;
            margin-right: 5px;
        }
        label {
            font-weight: 400;
        }
    }
    label, legend {
        font-weight: 700;
    }
    .form-error-validation {
        color: $red;
        @include rem('font-size', 12);
    }
    .phone select {
        display: none;
    }
}

.main-article .article-page {
    .related-articles {
        background: $white;
        padding: 15px;
        width: 100%;
        border: 1px solid $ltgrey;
        margin-bottom: 10px;
        @include clearfix;
        clear: both;
        // @media screen and (min-width: 767px) {
        //     max-width: 300px;
        //     float: left;
        //     margin-right: 20px;
        //     margin-left: -40px;
        // }
        ul, li {
            list-style-type: none;
        }
        ul {
            padding-left: 20px;
            margin: 0;
        }
        li {
            &::before {
                content: "\2022";
                color: $red;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
                vertical-align: top;
            }
        }
        h2, h3 {
            @include rem('font-size', 14);
        }
        h2 {
            margin-bottom: 20px;
        }
        article {
            display: inline-block;
            vertical-align: top;
            margin-top: 5px;
            h3 {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: 0 none;
                text-transform: none;
                color: $default;
                @include trans();
            }
            a:hover {
                h3 {
                    color: $red;
                }
            }
            p {
                color: $grey;
                display: none;
            }
        }
    }
}

// Article Gallery
.article-gallery {
    margin-bottom: 20px;
    clear: both;
    a {
        margin-bottom: 20px;
        @media screen and (max-width: 767px) {
            display: block;
        }
    }
    @media screen and (min-width: 768px) {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(4, calc((100% - 60px) / 4));
        a {
            margin-bottom: 0;
        }
    }
}

.video {
    padding-top: 55%;
    width: 100%;
    position: relative;
    .video-js {
        height: 100% !important; 
        width: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.ion-video-js .ion-video-logo {
    width: auto;
    height: 18px;
    margin: 0px;
    padding: 6px 9px;
    background: none;
    opacity: 1;
    font: 12px Helvetica, Arial, sans-serif;
    box-sizing: content-box;
    border: none;
    visibility: visible;
    text-size-adjust: auto;
    text-decoration: none;
    color: rgb(255, 255, 255);
    float: right;
    display: block;
    position: relative;
    > div {
        height: 100%;
        padding: 0px;
        opacity: 0.8;
    }
}

.ion-video-js .ion-video-svg {
    stroke: currentcolor;
    width: auto;
    height: 100%;
    margin: 0px;
    padding: 0px;
    background: none;
    opacity: 1;
    font: 12px Helvetica, Arial, sans-serif;
    box-sizing: content-box;
    border: none;
    visibility: visible;
    text-size-adjust: auto;
    text-decoration: none;
    fill: currentcolor;
}

.vjs-big-play-centered .vjs-big-play-button {
    z-index: 9999;
}

.ion-video-js .vjs-modal-dialog, 
.vjs-button>.vjs-icon-placeholder:before, 
.vjs-modal-dialog .vjs-modal-dialog-content {
    left: -20px !important;
}

.video-js .vjs-play-control {
    margin-left: 20px !important;
}

.ima-ad-container {
    top: 0;
    width: 100%;
    height: 100%;
   > div:first-of-type > div, video {
        width: 100%;
        height: 100%;
    }
    iframe {
        position: absolute !important;
        top: 0;
    }
}
