// Sidebar
.sidebar {
    position: relative;
    @media screen and (max-width: 768px) {
        clear: both;
    }
    @media screen and (min-width: 769px) {
        padding: 10px 20px;
        padding: 0;
    }
    img {
        max-width: 100%;
        margin: auto;
    }
    .follow-us {
        margin: 20px 0;
        h3 {
            margin-bottom: 20px;
        }
        a {
            display: block;
            margin: 5px 0;
            border: 1px solid $ltgrey;
            background: $white;
            padding: 10px;
            @include clearfix;
            @include rem('font-size', 14);
            &:hover {
                color: $red;
            }
            svg {
                float: left;
                vertical-align: middle;
                margin-right: 10px;
            }
            &.facebook {
                svg {
                    path {
                        fill: $facebook;
                    }
                }
            }
            &.twitter {
                svg {
                    path {
                        fill: $black;
                    }
                }
            }
            &.instagram {
                svg {
                    path {
                        fill: $instagram;
                    }
                }
            }
        }
    }
    .newsletter-subscribe {
        margin: 20px 0;
        padding: 20px;
        color: #FFF;
        background: #3B404C;
        input[type='email'] {
            width: 100%;
            margin-bottom: 10px;
            padding: 10px;
            appearance: none;
            @include trans();
            border: 1px solid $ltgrey;
            &:hover, &:focus {
                border-color: $red;
            }
        }
        button {
            width: 100%;
        }
        img {
            margin-top: 20px;
        }
    }
}

// Most Read / Editor's Choice
.read-choice {
    margin-bottom: 10px;
    input {
        display: none;
        &:checked + label {
            background: $red;
            color: $white;
        }
    }
    label {
        display: block;
        float: left;
        width: 50%;
        text-align: center;
        padding: 10px;
        border-bottom: 1px solid $ltgrey;
        margin-bottom: 20px;
        &:hover {
            color: $red;
            cursor: pointer;
        }
    }
    .content {
        @include clearfix;
        > div {
            display: none;
        }
        article {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $ltgrey;
            &:first-of-type {
                margin-top: 20px;
            }
            > a {
                display: grid;
                grid-template-columns: 45px auto;
                &:hover {
                    color: $red;
                }
                span {
                    text-align: center;
                    @include rem('font-size', 34);
                    @include font($notoSerif);
                }
                h4 {
                    align-self: center;
                    @include rem('font-size', 14);
                }
            }
        }
    }
    #most-read:checked ~ .content .most-read,
    #editors-choice:checked ~ .content .editors-choice {
     display: block;
   }
}

// Elections Button
.banner-button {
    margin: 20px 0;
}

// Weather
.weather {
    line-height: 1;
    background: $white;
    padding: 20px;
    border: 1px solid $ltgrey;
    h3 {
        width: 100%;
    }
    h4 {
        border-bottom: 0 none;
        padding-bottom: 0;
        margin-top: 10px;
        margin-bottom: 20px;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
    }
    img, section span {
        align-self: center;
    }
    img {
        width: 60%;
    }
    section span {
        @include rem('font-size', 50);
        text-align: center;
    }
    a {
        display: block;
        width: 100%;
        padding: 10px 15px;
        color: $white;
        background: $red;
        margin-top: 20px;
        position: relative;
        svg {
            position: absolute;
            right: 10px;
            path {
                fill: #FFF;
            }
        }
        &:hover {
            background: darken($red, 10%);
        }
    }
    .forecast {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, 50%);
    }
}

.on-sale-now {
    background: $black;
    color: $white;
    text-align: center;
    padding: 15px;
    @include clearfix;
    margin: 20px auto 0;
    max-width: 320px;
    width: 100%;
    h3, a {
        color: $white;
    }
    h3 {
        margin-bottom: 20px;
    }
    a {
        &:hover {
            color: $grey;
        }
    }
    .edition-subscribe {
        float: left;
        width: calc(100% / 2);
        padding: 0 10px;
    }
}