/* noto-sans-regular - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/noto-sans-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans'), local('NotoSans'),
       url('../../fonts/noto-sans-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/noto-sans-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/noto-sans-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/noto-sans-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/noto-sans-v7-latin-regular.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-italic - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/noto-sans-v7-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Italic'), local('NotoSans-Italic'),
       url('../../fonts/noto-sans-v7-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/noto-sans-v7-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/noto-sans-v7-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/noto-sans-v7-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/noto-sans-v7-latin-italic.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-700 - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/noto-sans-v7-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Bold'), local('NotoSans-Bold'),
       url('../../fonts/noto-sans-v7-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/noto-sans-v7-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/noto-sans-v7-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/noto-sans-v7-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/noto-sans-v7-latin-700.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-700italic - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../../fonts/noto-sans-v7-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Bold Italic'), local('NotoSans-BoldItalic'),
       url('../../fonts/noto-sans-v7-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/noto-sans-v7-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/noto-sans-v7-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/noto-sans-v7-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/noto-sans-v7-latin-700italic.svg#NotoSans') format('svg'); /* Legacy iOS */
}

/* noto-serif-regular - latin */
@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/noto-serif-v6-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Serif'), local('NotoSerif'),
       url('../../fonts/noto-serif-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/noto-serif-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/noto-serif-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/noto-serif-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/noto-serif-v6-latin-regular.svg#NotoSerif') format('svg'); /* Legacy iOS */
}
/* noto-serif-italic - latin */
@font-face {
  font-family: 'Noto Serif';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/noto-serif-v6-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Noto Serif Italic'), local('NotoSerif-Italic'),
       url('../../fonts/noto-serif-v6-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/noto-serif-v6-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/noto-serif-v6-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/noto-serif-v6-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/noto-serif-v6-latin-italic.svg#NotoSerif') format('svg'); /* Legacy iOS */
}
/* noto-serif-700 - latin */
@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/noto-serif-v6-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Noto Serif Bold'), local('NotoSerif-Bold'),
       url('../../fonts/noto-serif-v6-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/noto-serif-v6-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/noto-serif-v6-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/noto-serif-v6-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/noto-serif-v6-latin-700.svg#NotoSerif') format('svg'); /* Legacy iOS */
}
/* noto-serif-700italic - latin */
@font-face {
  font-family: 'Noto Serif';
  font-style: italic;
  font-weight: 700;
  src: url('../../fonts/noto-serif-v6-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Noto Serif Bold Italic'), local('NotoSerif-BoldItalic'),
       url('../../fonts/noto-serif-v6-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/noto-serif-v6-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/noto-serif-v6-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/noto-serif-v6-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/noto-serif-v6-latin-700italic.svg#NotoSerif') format('svg'); /* Legacy iOS */
}