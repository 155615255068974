// Style Imports
@import 'framework/_modern-normalize';
@import 'framework/_mixins';
@import 'framework/_fonts';
@import '../../../node_modules/react-alice-carousel/lib/scss/alice-carousel.scss';

// General
*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: subpixel-antialiased;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $grey;
}
::-moz-placeholder { /* Firefox 19+ */
    color: $grey;
}
:-ms-input-placeholder { /* IE 10+ */
    color: $grey;
}
:-moz-placeholder { /* Firefox 18- */
    color: $grey;
}

html, body {
    @include font();
    @include rem('font-size', 16);
    color: $default;
    line-height: calc(1.5em + .2vw);
    background: $background;
    min-height: 100%;
    &.is-open {
        overflow: hidden !important;
    }
}

body {
    padding-top: 152px;
    @media screen and (max-width: 767px) {
        padding-top: 62px;
    }
}

font {
    @include font();
    @include rem('font-size', 16);
}

#root, .app, .pushable, .pusher, .wrapper, .static-page {
    height: 100%;
}

main {
    min-height: calc(100% - (184px + 136px));
}

img {
    max-width: 100%;
    display: block;
}

a {
    @include trans();
    text-decoration: none;
    color: $black;
    &:hover {
        color: $black;
    }
}

b, strong {
    @include font($weight: 700);
}

.sr-only {
    display: none;
}

.show-on-focus {
    position: absolute;
    right: 20px;
    top: 28px;
    width: 1px;
    height: 1px;
    margin: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    &:focus {
        z-index: 20;
        width: auto;
        height: auto;
        clip: auto;
    }
}

// Headings
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    line-height: 1.2;
    font-weight: 400;
}

h1, h2 {
    text-transform: uppercase;
    @include rem('font-size', 16);
    border-bottom: 1px solid $ltgrey;
    margin-bottom: 10px;
    padding-bottom: 10px;
    span {
        display: inline-block;
        padding-bottom: 9px;
        margin-bottom: -10px;
        border-bottom: 1px solid $red;
        position: relative;
        bottom: -1px;
    }
}

h3 {
    text-transform: uppercase;
    @include rem('font-size', 16);
    border-bottom: 1px solid $ltgrey;
    margin-bottom: 10px;
    padding-bottom: 10px;
    span {
        display: inline-block;
        padding-bottom: 9px;
        margin-bottom: -10px;
        border-bottom: 1px solid $red;
        position: relative;
        bottom: -1px;
    }
}

h4 {
    @include rem('font-size', 18);
}

h5 {
    @include rem('font-size', 26);
}

// General
.wrapper {
    max-width: 990px;
    width: 100%;
    margin: auto;
    position: relative;
    // @include clearfix;
    @media screen and (max-width: 1160px) {
        padding: 0 20px;
    }
}

@media screen and (min-width: 760px) and (max-width:1160px) {
    main {
        .wrapper {
            padding: 0 20px;
        }
    }
}

main {
    position: relative;
    z-index: 20;
}

.half-image {
    float: left;
    width: 50%;
    text-align: center;
    padding: 0;
    margin: 20px 0 40px;
    img {
        margin: auto;
    }
    + p {
        clear: both;
    }
}

.one-third-image {
    text-align: center;
    padding: 0;
    margin: 20px 0 40px;
    @include clearfix;
    img {
        margin: auto;
        float: left;
        width: calc(100% / 3);
    }
    + p {
        clear: both;
    }
}


@media screen and (min-width: 768px) {
    .half {
        float: left;
        width: 50%;
    }
}

// Loader
.dimmer.visible {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
}
.ui.dimmer {
    z-index: 9 !important;
}

// Main
.pushable:not(body) {
    transform: none;
}

.ui.segment {
    background: none;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    border: 0 none;
}

// Buttons
.blk-btn {
    background: $black;
    color: $white;
    @include rem('font-size', 24);
    width: 80%;
    margin: 0 10%;
    display: block;
    text-align: center;
    padding: 10px;
    &:hover {
        color: $white;
        background: #666;
    }
}

.red-btn {
    background: $red;
    color: $white;
    margin: 20px auto;
    display: inline-block;
    text-align: center;
    padding: 10px;
    border: 1px solid $red;
    &:hover {
        color: $red;
        background: $white;
    }
}

.loading-article {
    margin-top: 75px;
    text-align: center;
}

@import 'modules/_header';
@import 'modules/_footer';
@import 'modules/_form';
@import 'modules/_adverts';
@import 'modules/_article-list';
@import 'modules/_sidebar';
@import 'modules/_main-article';
@import 'modules/_static-pages';
@import 'modules/_oovvuu';